import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { setDrawerOpen } from '../../../actions'
import { useDispatch } from 'react-redux'
import { mobileVW, desktopBreakpoint, colors, font } from '../../../styles'

// Utils
import { getPathname } from '../../../utils'

// Hooks
import useLayoutCtx from '../../../hooks/context/useLayoutCtx'
import useIsMobile from '../../../hooks/useIsMobile'
import useAuth from '../../../hooks/useAuth'

// Components
import { Link } from 'gatsby'
import Bookmark from '../../graphics/Bookmark'

const BookmarkWrapper = styled.div`
  position: absolute;
  font-family: ${font.larsseit};
  bottom: ${mobileVW(15)};
  left: ${mobileVW(14)};
  background-color: ${props => (props.label ? `white` : `initial`)};
  border-radius: ${props => (props.label ? `${mobileVW(50)}` : `initial`)};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.brownDarkest};
  width: ${props => (props.label ? `max-content` : `${mobileVW(14)}`)};
  height: ${props => (props.label ? `max-content` : `${mobileVW(18)}`)};
  z-index: 8;

  button {
    width: 100%;
    height: 100%;
    display: contents;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    width: ${props => (props.label ? `max-content` : `14px`)};
    height: ${props => (props.label ? `max-content` : `18px`)};
    bottom: 15px;
    left: 21px;
    border-radius: ${props => (props.label ? `80px` : `50%`)};
  }

  svg {
    width: ${mobileVW(14)};
    height: ${mobileVW(18)};
    margin-left: ${props => (props.label ? `${mobileVW(25)}` : '0')};
    fill: ${props =>
      props.label && props.saved ? `black` : props.saved ? 'white' : `none`};
    path {
      stroke: ${props => (props.label ? `black` : `white`)};
      stroke-width: 2px;
    }
    @media (min-width: ${desktopBreakpoint}) {
      width: 14px;
      height: 18px;
      margin-left: ${props => (props.label ? `10px` : '0')};
    }
  }
`

const Label = styled.div`
  font-size: ${mobileVW(14)};
  padding: ${mobileVW(12)} 0 ${mobileVW(12)} ${mobileVW(12)};
  margin-right: ${mobileVW(21)};
  line-height: 1;
  text-transform: uppercase;
  @media (min-width: ${desktopBreakpoint}) {
    font-size: 18px;
    padding: 6px 0 6px 12px;
    margin-right: 11px;
  }
`

const Button = styled.button``

const SaveArticleButton = ({ article, label, onRemoveFavourite }) => {
  const { user, ready } = useAuth()
  const { node_locale, authenticationPage } = useLayoutCtx()
  const [savedArticle, setSavedArticle] = useState(false)

  const { slug, title, image, id, preview, time } = article

  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  //this could be error because the .saved can also not be availible
  useEffect(() => {
    if (user) {
      firebase
        .firestore()
        .collection('users')
        .doc(user.uid)
        .collection('articles')
        .doc(id)
        .onSnapshot(doc => {
          if (doc.exists) {
            let userDate = doc.data()
            if (userDate.saved === true) {
              setSavedArticle(true)
            }
            if (userDate.saved === false) {
              setSavedArticle(false)
            }
          }
        })
    }
  }, [user])

  const saveArticle = () => {
    if (savedArticle === false) {
      firebase
        .firestore()
        .collection('users')
        .doc(user.uid)
        .collection('articles')
        .doc(id)
        .set(
          {
            slug,
            title,
            image,
            id,
            preview,
            time,
            saved: true,
          },
          { merge: true },
        )
      setSavedArticle(true)
      firebase
        .firestore()
        .collection('articles')
        .doc(id)
        .collection('details')
        .doc('count')
        .set(
          {
            saves: firebase.firestore.FieldValue.increment(1),
          },
          { merge: true },
        )
    } else {
      firebase
        .firestore()
        .collection('users')
        .doc(user.uid)
        .collection('articles')
        .doc(id)
        .set(
          {
            saved: false,
          },
          { merge: true },
        )

      setSavedArticle(false)

      firebase
        .firestore()
        .collection('articles')
        .doc(id)
        .collection('details')
        .doc('count')
        .set(
          {
            saves: firebase.firestore.FieldValue.increment(-1),
          },
          { merge: true },
        )

      if (onRemoveFavourite && typeof onRemoveFavourite === 'function') {
        onRemoveFavourite(id)
      }
    }
  }

  return (
    <BookmarkWrapper
      label={user && label}
      className='bookmark'
      saved={savedArticle}
    >
      {ready && user ? (
        <button onClick={() => saveArticle()}>
          <div>
            <Bookmark />
            {label && <Label>save</Label>}
          </div>
        </button>
      ) : isMobile ? (
        <Link
          to={getPathname(
            node_locale,
            `${authenticationPage.slug}?signInSuccessUrl=/${node_locale}/${slug}`,
          )}
        >
          <Bookmark />
        </Link>
      ) : (
        <Button onClick={() => dispatch(setDrawerOpen(true))}>
          <Bookmark />
        </Button>
      )}
    </BookmarkWrapper>
  )
}

export default SaveArticleButton
