import React, { useState } from 'react'
import { mobileVW, font, colors, desktopBreakpoint } from '../../../styles'
import styled from 'styled-components'

// Utils
import { getPathname } from '../../../utils'

// Hooks
import useLayoutCtx from '../../../hooks/context/useLayoutCtx'
import useContentfulId from '../../../hooks/useContentfulId'

// Components
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import AddToCart from '../../layout/AddToCart'
import Price from '../Price'
import SaveArticleButton from './saveArticleButton'
import Button from './button'
import ArticleLabel from './articleLabel'

// VerticalCard
const Card = styled.div`
  width: ${mobileVW(164)};
  height: fit-content;
  min-height: ${props =>
    props.mini ? `${mobileVW(315)}` : `${mobileVW(310)}`};
  // border-radius: ${mobileVW(6)};
  // padding: ${mobileVW(16)};
  text-align: left;
  // border: solid ${mobileVW(1)} rgba(101, 71, 63, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: ${props => (props.mini ? `flex-start` : `space-between`)};
  background: white;
  padding: ${mobileVW(16)} 0;
  @media (min-width: ${desktopBreakpoint}) {
    width: 262px;
    min-height: 460px;
    max-width: 1168px;
    padding: 30px 0;

    .card-image-wrapper {
      .gatsby-image-wrapper {
        transition: all 0.9s ease-in-out;
      }

      > img {
        transition: all 0.9s ease-in-out;
      }
    }

    &:hover {
      // box-shadow: 0px 0px 10px rgba(101, 71, 63, 0.2);
      .card-image-wrapper {
        .gatsby-image-wrapper {
          transform: scale(1.1);
        }

        > img {
          transform: scale(1.1);
        }
      }
    }
  }
`

const ImageWrapper = styled.div`
  width: ${mobileVW(164)};
  height: ${mobileVW(164)};
  border-radius: ${mobileVW(6)};
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media (min-width: ${desktopBreakpoint}) {
    width: 262px;
    max-width: 100%;
    height: 262px;
    border-radius: 6px;
  }
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`

const ImageLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Title = styled.h3`
  font-family: ${font.ben};
  color: ${colors.brownDarkest};
  font-size: ${mobileVW(18)};
  line-height: 1.2em;
  text-transform: lowercase;
  margin-top: ${props => (props.mini ? `0` : `${mobileVW(16)}`)};
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${props => (props.mini ? `1` : `3`)};
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: normal;
  @media (min-width: ${desktopBreakpoint}) {
    -webkit-line-clamp: ${props => (props.mini ? `1` : `2`)};
    margin-top: ${props => (props.mini ? `0` : `20px`)};
    color: ${props =>
      props.mini ? `${colors.brownDark}` : `${colors.brownDarkest}`};
    font-size: ${props => (props.mini ? `28px` : `24px`)};
    line-height: ${props => (props.mini ? `31px` : `28px`)};
    padding-bottom: 2px;
    margin-bottom: 6px;
  }
`

const TextWrapper = styled.div`
  text-align: left;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  span {
    text-transform: lowercase;
    font-family: ${font.ben};
    font-weight: normal;
    font-size: ${mobileVW(18)};
    @media (min-width: ${desktopBreakpoint}) {
      font-size: 18px;
    }
  }
  flex-wrap: wrap;
  padding-top: ${mobileVW(12)};
  @media (min-width: ${desktopBreakpoint}) {
    justify-content: flex-start;
    padding-top: ${props => (props.mini ? `0` : `12px`)};
    cursor: pointer;
  }
`

const Type = styled.div`
  color: #c8c8c8;
  font-size: ${mobileVW(14)};
  line-height: ${mobileVW(24)};
  text-transform: uppercase;
  @media (min-width: ${desktopBreakpoint}) {
    font-size: 14px;
    line-height: 1;
  }
`

const Time = styled.div`
  font-family: ${font.larsseit};
  color: #c9c9c9;
  text-transform: uppercase;
  font-size: ${mobileVW(14)};
  line-height: ${mobileVW(24)};
  margin-top: ${mobileVW(6)};
  @media (min-width: ${desktopBreakpoint}) {
    margin-top: 8px;
    font-size: 14px;
    line-height: 24px;
  }
`

const PriceWrapper = styled.div`
  font-family: ${font.larsseit};
  color: ${colors.brownDarkest};
  font-size: ${mobileVW(14)};
  line-height: ${mobileVW(24)};
  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${props => (props.mini ? `14px` : `20px`)};
    line-height: 24px;
  }
`

const CartButton = styled.div`
  color: ${colors.brownDarkest};
  border: solid ${mobileVW(2)} ${colors.beigeUltraLight};
  padding: ${mobileVW(10)} ${mobileVW(10)};
  border-radius: ${mobileVW(100)};
  line-height: 1;
  flex-grow: 1;
  text-align: center;
  @media (min-width: ${desktopBreakpoint}) {
    border: solid 2px ${colors.beigeUltraLight};
    border-radius: 100px;
    margin-top: ${props => (props.mini ? `8px` : `24px`)};
    padding: ${props => (props.mini ? `10px` : `14px`)};
    font-size: 18px;

    &:hover {
      color: ${colors.orangeLight};
      border-color: ${colors.orangeLight};
    }
  }
`

const Description = styled.p`
  margin-top: ${mobileVW(8)};
  font-family: ${font.larsseit};
  color: ${colors.brownDarkest};
  font-size: ${mobileVW(14)};
  line-height: ${mobileVW(18)};
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${props => (props.showAll ? `none` : '3')};
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media (min-width: ${desktopBreakpoint}) {
    font-size: 16px;
    line-height: 22px;
    margin-top: 8px;
  }
`

const LabelWrapper = styled.div`
  position: absolute;
  bottom: ${mobileVW(10)};
  right: ${mobileVW(8)};
  @media (min-width: ${desktopBreakpoint}) {
    bottom: 12px;
    right: 16px;
  }
`

const ArticleProductCard = ({ content, isOrder, mini, onRemoveFavourite }) => {
  const { node_locale } = useLayoutCtx()
  const [showFullDescription, setShowFullDescription] = useState(false)

  const {
    title,
    id: articleId,
    slug,
    imageThumbnail,
    image,
    preview,
    productDescriptionShort,
    time,
    label,
    shopifyProduct,
    totalPrice,
  } = content

  const id = useContentfulId('article', articleId)

  const url = getPathname(node_locale, slug)

  const ReadTime = json => {
    const text = json.content.reduce(
      (acc, { content }) =>
        acc + (content[0] && content[0].value ? ` ${content[0].value}` : ''),
      '',
    )

    const wordsPerMinute = 240
    const words = text.split(' ').length
    const lengthMin = Math.floor(words / wordsPerMinute)

    return lengthMin === 0 ? 1 : lengthMin
  }

  const newTime = content.content
    ? ReadTime(content.content.json)
    : time
    ? time
    : null
  const isOldArticle = !imageThumbnail && image && !image.fluid ? true : false
  const isArticle = preview || isOldArticle || label ? true : false

  const formatDate = date => {
    let x = new Date(date).toISOString().substring(0, 10)
    let newDate = x.split('-')
    return `${newDate[2]}/${newDate[1]}/${newDate[0]}`
  }

  return (
    <>
      <Card mini={mini} className='product_card'>
        <ImageWrapper className='card-image-wrapper' mini={mini}>
          {isOldArticle ? (
            <img src={image} />
          ) : (
            <Image fluid={imageThumbnail?.fluid || image?.fluid} />
          )}
          {label ? (
            <LabelWrapper>
              <ArticleLabel color={label.colorCode} text={label.title} />
            </LabelWrapper>
          ) : null}
          {isArticle ? (
            <SaveArticleButton
              onRemoveFavourite={onRemoveFavourite}
              article={{
                slug,
                title,
                image,
                id,
                preview,
                time: newTime,
              }}
            />
          ) : null}
          <ImageLink to={url} />
        </ImageWrapper>
        <Link to={url} className='card-text-wrapper'>
          <TextWrapper>
            <Title title={title}>{title}</Title>
            {productDescriptionShort || mini ? (
              <Type mini={mini}>{productDescriptionShort || title}</Type>
            ) : null}
            {newTime ? <Time>{newTime} min read</Time> : null}
            {shopifyProduct ? (
              <PriceWrapper mini={mini}>
                <Price shopifyProduct={shopifyProduct} />
              </PriceWrapper>
            ) : isOrder ? (
              <PriceWrapper>€{totalPrice.amount}</PriceWrapper>
            ) : null}
            {isArticle ? (
              <Description showAll={showFullDescription}>{preview}</Description>
            ) : null}
          </TextWrapper>
        </Link>
        {shopifyProduct ? (
          <ButtonWrapper mini={mini}>
            <CartButton mini={mini}>
              <AddToCart handle={shopifyProduct.handle} />
            </CartButton>
          </ButtonWrapper>
        ) : isArticle ? (
          <ButtonWrapper>
            <Button
              ctaType='link'
              cta={() => setShowFullDescription(!showFullDescription)}
              text={showFullDescription ? 'READ LESS' : 'READ MORE'}
            />
          </ButtonWrapper>
        ) : null}
      </Card>
    </>
  )
}

export default ArticleProductCard
